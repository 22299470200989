import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Service from './Service';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/About" element={ <About/>} />
        <Route path="/Service" element={ <Service/>} />
        <Route path="/Contact" element={ <Contact/> } />
      </Routes>
    </BrowserRouter>
    </>
);


