import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { MdCall , MdEmail } from "react-icons/md";

const Footer = () =>{
    return(
        <>
            <div className="main-footer">
                <div className="container">
                    <div className="footer-inner">
                        <div className="footer-left">
                            <div className="footer-icon1">
                                    <FaLocationDot />
                            </div>
                            <div className="footer-content">
                                    <span>B/302, Siddharth Annexe 4, Near Bansal Mall,<br></br>
                                      Sama-Savli Road, Vadodara - 390002</span>
                            </div>
                        </div>
                        <div className="footer-middel">
                            <div className="footer-icon2">
                                    <MdCall/>
                            </div>
                            <div className="footer-content">
                                    <span>Phone:+91 942 6572632</span>
                            </div>
                        </div>
                        <div className="footer-right">
                            <div className="footer-icon3">
                                    <MdEmail/>
                            </div>
                            <div className="footer-content">
                                    <span>Email:pelagiclink55@gmail.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="bottom-inner">
                        <div className="bottom-content">
                            <p> Copyright information and links to legal disclaimers</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer