import React from "react";
import Header from "./Header";
import Header1 from "./Header1";
import Slider from "./Slider";
import Footer from "./Footer";

const Home = () =>{
    return(
        <>
            <Header/>
            <Header1/>
            <Slider/>
            <Footer/>
        </>
    )
}

export default Home