import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slider = () =>{
        var test = {
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                }
            }
         }
    return(
        <>
            <div className="main-body">
                <div className="main-slider">
                    <div className="slider-inner">
                    <OwlCarousel {...test} className='owl-theme' loop margin={3} dots={false} autoplay>
                        <div className="item">
                            <div className="silder-image">
                                <img src="assest/image/rud1.png" alt="rud1"></img>
                            </div>
                        </div>
                        <div className="item">
                            <div className="silder-image">
                                <img src="assest/image/rud3.png" alt="rud3"></img>
                            </div>
                        </div>
                        <div className="item">
                            <div className="silder-image">
                                <img src="assest/image/rud4.png" alt="rud4"></img>
                            </div>
                        </div>
                    </OwlCarousel>
                    </div>
                </div>
            
                <div className="silder-content">
                    <div className="container">
                        <div className="content-inner">
                            <div className="silder-text">
                                <b>Welcome to Pelagic Link: Your Trusted Partner in Industrial Solutions</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Slider