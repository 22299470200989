import React from "react";

const Aboutus = () =>{
    return(
        <>
            <div className="main-ship">
                <div className="ship-inner">
                    <div className="ship-image">
                        <img src="assest/image/about.jpg" alt="about"></img>
                    </div>
                </div>
            </div>
            
            <div className="ship-content">
                <div className="container">
                    <div className="ship-inner">
                        <div className="ship-text">
                            <h1>About Us</h1>
                            <p>At Pelagic Link, we're more than just a  industrial product import-export company —
                                we're your strategic partner in navigating the complex world of global  trade. With a
                                steadfast commitment to quality, reliability, and sustainability, we offer tailored solutions to meet
                                your unique  industrial needs.</p>
                            <b>Why Choose Us?</b>
                            <p>1. &nbsp;<b className="heading">Expert Representation:</b>  As your trusted agent, we bring a wealth of knowledge and
                                experience to the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;table. Our team of experts understands the intricacies of the chemical
                                various industry and is commited &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to representing your interests with professionalism and
                                integrity.<br></br><br></br>
                                2. &nbsp;<b className="heading">Global Reach:</b> With a vast network of suppliers, manufacturers, and buyers worldwide,
                                we have the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;reach to source the highest quality chemicals industrial products at competitive
                                prices and connect you &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with lucrative market opportunities across the globe.<br></br><br></br>
                                3. &nbsp;<b className="heading">Quality Assurance: </b> Quality is at the forefront of everything we do. From stringent
                                supplier selection to &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rigorous quality control measures, we ensure that every product meets
                                the highest standards of quality &nbsp;&nbsp;&nbsp;&nbsp; and safety.<br></br><br></br>
                                4. &nbsp;<b className="heading">Efficiency and Transparency: </b> We understand the importance of efficiency and
                                transparency in every &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transaction. From negotiating contracts to overseeing logistics, we
                                ensure that every step of the process &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;is handled with precision and clarity.<br></br><br></br>
                                5. &nbsp;<b className="heading">Tailored Solutions: </b> Your success is our priority. That's why we offer customized solutions
                                tailored to &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;your specific needs and objectives, whether it's sourcing a rare chemical industrial
                                product or optimizing &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;your supply chain for maximum efficiency.<br></br><br></br>
                                6. &nbsp;<b className="heading">Reliable Support: </b> With <b className="pelagic">Pelagic Link</b> by your side, you can count on reliable
                                support every step of the &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;way. Our dedicated team is always available to address your
                                inquiries, provide updates, and offer &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;guidance to help you navigate the complexities of
                                international trade.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutus