import React from "react";
import Header from "./Header";
import Header1 from "./Header1";
import Footer from "./Footer";
import { MdCall, MdEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";

const Contact = () =>{
    return(
        <>
            <Header></Header>
            <Header1></Header1>

            <div className="main-contact">
                <div className="contect-inner">
                    <div className="contect-image">
                        <img src="assest/image/contect.jpg" alt="contect"></img>
                    </div>
                </div>
            </div>

            <div className="contect-content">
                <div className="container">
                    <div className="contect-inner">
                        <div className="contect-text">
                            <h1>Contect Us</h1>
                            <p>Pelagic Link<br></br>B/302, Siddharth Annexe 4, Near Bansal Mall,<br></br> Sama-Savli Road, Vadodara - 390002</p>
                        </div>
                        <div className="contect-icon">
                            <div className="call"><MdCall/>: +91 942 6572632</div>
                            <div className="call"><FaWhatsapp/>: +91 942 6572632</div>
                            <div className="call"><MdEmail/><a href="#">pelagiclink55@gmail.com</a></div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer></Footer>
        </>
    )
}

export default Contact