import React from "react";
import Header from "./Header";
import Header1 from "./Header1";
import Footer from "./Footer";

const Service = () =>{
    return(
        <>
            <Header></Header>
            <Header1></Header1>
            
            <div className="main-service">
                <div className="service-inner">
                    <div className="service-image">
                        <img src="assest/image/service.jpg" alt="service"></img>
                    <div className="services">
                        <b>SERVICES</b>
                    </div>
                    </div>
                </div>
            </div>

            <div className="service-content">
                <div className="container">
                    <div className="service-inner">
                        <div className="service-text">
                            <h1>SERVICES</h1>
                            <p>An indent agent connects buyers and sellers by bringing them together to negotiate deals and
                            arrange the terms and conditions of transactions. The indent agent finalizes deals by arranging leters
                            of indent that specify terms like delivery schedules. Once payment is received through the leter of
                            credit process, the indent agent's role is complete. Indent agents maintain relationships with
                            importers to understand future needs and ensure timely supply of goods as required. They must
                            keep updated on market conditions like quality, pricing and alternative suppliers.</p>
                            <b>We Serve for Various Industries</b>
                            <p>• Life sciences<br></br>
                                &nbsp;&nbsp;– Life sciences include differentiated chemical and biological substances, pharmaceuticals,
                                diagnostics, &nbsp;&nbsp;&nbsp;&nbsp;animal health products, vitamins, and pesticides.<br></br>
                                • Specialty chemicals<br></br>
                                &nbsp;&nbsp;– Products include electronic chemicals, industrial gases, adhesives and sealants as well as coatings,
                                &nbsp;&nbsp;industrial and institutional cleaning chemicals, and catalysts.<br></br>
                                • Consumer products<br></br>
                                &nbsp;&nbsp;– Consumer products include direct product of chemicals such as soaps, detergents, and cosmetics.<br></br>
                                &nbsp;&nbsp;– Polymers and specialty chemicals examples are plastics, cleaning materials, cosmetics, paints &
                                coatings, &nbsp;&nbsp;&nbsp;&nbsp;electronics, automobiles and the materials used in home construction.<br></br>
                                &nbsp;&nbsp;– The specialty products as pesticides, specialty polymers, electronic chemicals, surfactants,
                                construction &nbsp;&nbsp;&nbsp;&nbsp;chemicals, Industrial Cleaners, flavours and fragrances, specialty coatings, printing inks,
                                water-soluble &nbsp;&nbsp;&nbsp;&nbsp;polymers, food additives, paper chemicals, oil field chemicals, plastic adhesives,
                                adhesives and sealants, &nbsp;&nbsp;&nbsp;&nbsp;cosmetic chemicals, water management chemicals, catalysts, and textile
                                chemicals.<br></br>
                                &nbsp;&nbsp;– Many More END CONSUMER Products…</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    )
}
export default Service