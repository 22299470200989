import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
          <div className="home-page">
            <div className="main-header">
                <div className="container">
                    <div className="header-inner">
                        <div className="header-left">
                            <div className="logo-block">
                                <div className="logo">
                                <Link to="/"><img src="assest/image/logo1.jpg" alt="logo1"></img></Link>
                                </div>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="company-name">
                                <div className="text">
                                    <b>Pelagic Link</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default Header