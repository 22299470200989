import React from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import { MdCall, MdEmail } from "react-icons/md";
import Header1 from "./Header1";
import Aboutus from "./Aboutus";
import Footer from "./Footer";


const About = () =>{
    return(
        <>
            <Header></Header>
            <Header1></Header1>
            <Aboutus></Aboutus>
            <Footer></Footer>
        </>
    )
}

export default About