import React from "react";
import { MdCall , MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";



const Header1 = () =>{
    return(
        <>
        <div className="header-menu">
            <div className="container">
                <div className="menu-inner">
                    <div className="menu-block">
                        <ul className="menu">
                            <li><Link to="/"><b className="home">HOME</b></Link></li>
                            <li><Link to="/About"><b>ABOUT US</b></Link></li>
                            <li><Link to="/Service"><b>SERVICES</b></Link></li>
                            <li><Link to="/Contact"><b>CONTACT US</b></Link></li>
                        </ul>
                    </div>
                    <div className="icon-block">
                        <div className="icon">
                            <span className="text"><MdCall/> <b>+91 942 6572632</b></span>&nbsp;&nbsp;
                            <span className="text"><MdEmail/> <b>pelagiclink55@gmail.com</b></span>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}

export default Header1